import React from 'react'
import PropTypes from 'prop-types'

import Layout from 'Layout'
import { HTMLContent } from 'Content'
import SEO from 'SEO'

import createContentBlockMarkdown from './_createContentBlockMarkdown'
import View from './ArticleView'

const Article = ({ article, otherArticles }) => {
  const {
    frontmatter,
    html,
    excerpt,
    fields
  } = article

  const {
    title,
    featureImage,
    author,
    tags,
    publishDate
  } = frontmatter

  const contentBlocks = frontmatter.contentBlocks ? createContentBlockMarkdown(frontmatter.contentBlocks) : null

  return (
    <Layout>
      <SEO
        title={title}
        description={excerpt}
        slug={fields.slug}
      />
      <View
        content={html}
        contentComponent={HTMLContent}
        title={title}
        featureImage={featureImage}
        author={author}
        tags={tags}
        publishDate={publishDate}
        contentBlocks={contentBlocks}
        slug={fields.slug}
        otherArticles={otherArticles}
      />
    </Layout>
  )
}

Article.propTypes = {
  article: PropTypes.object.isRequired,
  otherArticles: PropTypes.array
}

export default Article
