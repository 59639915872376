import React from 'react'

import Content from 'Content'
import ContentBlock from 'ContentBlock'

const ArticleViewBody = ({ content, contentComponent, contentBlocks, isPreview }) => {
  const ArticleContent = contentComponent || Content

  return (
    <div
      className='c-article-body'
    >
      {
        content &&
          <div
            className='o-container--prose'
          >
            <ArticleContent
              content={content}
              className='c-article-body__rte'
            />
          </div>
      }
      {
        contentBlocks &&
        contentBlocks.map((item, key) =>
          <ContentBlock
            type={item.type}
            key={key}
            isPreview={isPreview}
            {...item}
          />
        )
      }
    </div>
  )
}

export default ArticleViewBody
