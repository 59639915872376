import React from 'react'

import useAudioPlayer from 'AudioPlayer/_useAudioPlayer'
import EpisodeRollItem from 'EpisodeRoll/EpisodeRollItem'
import Content from 'Content'

import Wrapper from './ContentBlockWrapper'

const ContentBlockEpisode = ({ episode, body, episodeAlignment, contentComponent, isPreview }) => {
  const BlockContent = contentComponent || Content

  const {
    frontmatter,
    fields
  } = episode

  const {
    title,
    show,
    publishDate,
    audioDuration,
    audioUrl
  } = frontmatter

  const track = {
    url: audioUrl,
    duration: audioDuration,
    name: title,
    slug: fields.slug,
    show: Object.assign(show.frontmatter, show.fields)
  }

  const { currentTrack } = useAudioPlayer()
  const isPlaying = (currentTrack && track.url === currentTrack.url)

  if (isPreview) {
    return (
      <Wrapper wide>
        <div className='o-layout'>
          {
            episodeAlignment === 'Left'
              ?
                <div className='o-layout__item u-width-1/3@md u-width-1/3@lg u-width-1/4@xl'>
                  <EpisodeRollItem isPreview={isPreview} />
                </div>
              : null
          }
          <div className='o-layout__item u-width-2/3@md u-width-2/3@lg u-width-3/4@xl'>
            <BlockContent
              content={body}
              className={`u-margin-top-large u-margin-top-none@md ${episodeAlignment === `Left` ? `u-padding-right-huge@xl` : `u-padding-left-huge@xl`}`}
            />
          </div>
          {
            episodeAlignment === 'Right'
              ?
                <div className='o-layout__item u-width-1/3@md u-width-1/3@lg u-width-1/4@xl'>
                  <EpisodeRollItem isPreview={isPreview} />
                </div>
              : null
          }
        </div>
      </Wrapper>
    )
  }

  return (
    <Wrapper wide>
      <div className='o-layout'>
        {
          episodeAlignment === 'Left'
            ?
              <div className='o-layout__item u-width-1/3@md u-width-1/3@lg u-width-1/4@xl'>
                <EpisodeRollItem
                  title={title}
                  slug={fields.slug}
                  track={track}
                  show={track.show}
                  date={publishDate}
                  duration={audioDuration}
                  isPlaying={isPlaying}
                  isPreview={isPreview}
                />
              </div>
            : null
        }
        <div className='o-layout__item u-width-2/3@md u-width-2/3@lg u-width-3/4@xl'>
          <BlockContent
            content={body}
            className={`u-margin-top-large u-margin-top-none@md ${episodeAlignment === `Left` ? `u-padding-right-huge@xl` : `u-padding-left-huge@xl`}`}
          />
        </div>
        {
          episodeAlignment === 'Right'
            ?
              <div className='o-layout__item u-width-1/3@md u-width-1/3@lg u-width-1/4@xl'>
                <EpisodeRollItem
                  title={title}
                  slug={fields.slug}
                  track={track}
                  show={track.show}
                  date={publishDate}
                  duration={audioDuration}
                  isPlaying={isPlaying}
                  isPreview={isPreview}
                />
              </div>
            : null
        }
      </div>
    </Wrapper>
  )
}

export default ContentBlockEpisode
