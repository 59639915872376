import React from 'react'

import Image from 'Image'

const ContentBlockFullWidthImage = ({ image }) => (
  <figure className='u-img-full u-margin-bottom-large u-margin-top-large u-margin-bottom-huge@md u-margin-top-huge@md'>
    <Image
      image={image}
      width={684}
      sizes={[
        `${Image.breakpoint('md')} 50vw`,
        '100vw'
      ]}
      alt={``}
    />
  </figure>
)

export default ContentBlockFullWidthImage
