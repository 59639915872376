import React from 'react'

import Content from 'Content'

import Wrapper from './ContentBlockWrapper'

const ContentBlockRichText = ({ body, contentComponent }) => {
  const BlockContent = contentComponent || Content

  return (
    <Wrapper>
      <BlockContent
        content={body}
      />
    </Wrapper>
  )
}

export default ContentBlockRichText
