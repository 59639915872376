import React from 'react'

import Image from 'Image'

const ArticleViewImage = ({ image }) => (
  <figure
    className='c-article-image'
  >
    <Image
      image={image}
      width={1450}
      sizes={[
        `${Image.breakpoint('md')} calc(100vw - 54px)`,
        `${Image.breakpoint('container')} calc((100vw - 54px) / 3)`,
        '1450px'
      ]}
      alt={``}
    />
  </figure>
)

export default ArticleViewImage
