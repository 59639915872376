import React from 'react'

import View from './ContentBlockView'

const ContentBlock = ({ type, isPreview, ...props }) => (
  <View
    type={type}
    isPreview={isPreview}
    {...props}
  />
)

export default ContentBlock
