import React from 'react'
import get from 'lodash.get'

import SocialShare from 'SocialShare'
import CategoriesRoll from 'CategoriesRoll'

const ArticleViewHeader = ({ title, publishDate, author, slug, tags, isPreview }) => (
  <header className='c-hero c-article-header'>
    <div className='o-container'>
      {
        get(tags, 'frontmatter', null) &&
          <aside
            className='c-article-tags u-ta-c'
          >
            <CategoriesRoll categories={tags.map(({ frontmatter }) => frontmatter)} />
          </aside>
      }
      <h1
        className='c-hero__title u-ta-c u-margin-bottom-small'
      >
        {title}
      </h1>
      <div
        className='c-article-meta u-ta-c'
      >
        <h2
          className='e-h5 u-margin-bottom-none u-dis-ib u-margin-right-small'
        >
          <span
            className='u-fw-bold'
          >
            Posted:{` `}
          </span>
          <span
            className='u-color-smoke u-fw-normal'
          >
            {publishDate}
          </span>
        </h2>
        <h3
          className='e-h5 u-margin-bottom-none u-dis-ib'
        >
          <span
            className='u-fw-bold'
          >
            Words:{` `}
          </span>
          <span
            className='u-color-smoke u-fw-normal'
          >
            {author}
          </span>
        </h3>
      </div>
      {
        !isPreview &&
          <div
            className='c-article-share u-margin-top u-ta-c'
          >
            <SocialShare
              title={title}
              slug={slug}
            />
          </div>
      }
    </div>
  </header>
)

export default ArticleViewHeader
