import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import sanitizer from 'utilities/sanitizer'
import getSiblingArticles from 'utilities/getSiblingArticles'

import Article from 'Article'

const ArticleTemplate = ({ data }) => {
  const { markdownRemark: currentArticle, allArticles } = data
  const { edges: allArticleEdges } = allArticles

  const sanitizedArticles = sanitizer(allArticleEdges)
  const siblingArticles = getSiblingArticles(sanitizedArticles, currentArticle)

  return (
    <Article
      article={currentArticle}
      otherArticles={siblingArticles}
    />
  )
}

ArticleTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default ArticleTemplate

export const ArticleQuery = graphql`
  query Article($id: String!) {
    markdownRemark(
      id: {
        eq: $id
      }
    ) {
      id
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
        featureImage {
          default
        }
        publishDate(formatString: "MMMM D, YYYY")
        author
        contentBlocks {
          type
          videoUrl
          embedHtml
          body
          episodeAlignment
          episode {
            fields {
              slug
            }
            frontmatter {
              title
              audioUrl
              audioDuration
              publishDate(formatString: "MMMM D, YYYY")
              show {
                fields {
                  slug
                }
                frontmatter {
                  title
                  artwork
                }
              }
            }
          }
          image
          imageLeft
          imageRight
        }
      }
    }
    allArticles: allMarkdownRemark(
      sort: {
        fields: [frontmatter___publishDate]
        order: DESC
      }
      filter: {
        frontmatter: {
          templateKey: {
            eq: "templates/ArticleTemplate"
          }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
