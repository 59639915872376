import React from 'react'
import classnames from 'classnames'

const ContentBlockWrapper = ({ children, wide }) => (
  <div
    className='c-content-block'
  >
    <div
      className={classnames({
        'o-container--prose': !wide,
        'o-container--thin': wide
      })}
    >
      {children}
    </div>
  </div>
)

export default ContentBlockWrapper
