import React from 'react'
import { Link } from 'gatsby'

import SocialShare from 'SocialShare'

import { ReactComponent as ArrowRightIcon } from 'assets/img/svg/arrow-right.svg'
import { ReactComponent as ArrowLeftIcon } from 'assets/img/svg/arrow-left.svg'

const ArticleViewFooter = ({ articles, slug, title, isPreview }) => (
  <aside
    className='o-container--prose'
  >
    {
      !isPreview &&
        <div
          className='c-article-share c-article-share--footer u-padding-bottom u-margin-top-large u-margin-top-huge@md u-padding-bottom-large u-padding-bottom-huge@md'
        >
          <SocialShare
            slug={slug}
            title={title}
            dark
          />
        </div>
    }
    {
      articles &&
        <nav
          className='c-article-nav u-ta-c u-margin-top-large u-margin-top-huge@md'
        >
          <ul
            className='c-article-nav__list o-cleanlist'
          >
            {
              articles[0] &&
                <li
                  className='c-article-nav__item'
                >
                  <Link
                    to={articles[0].slug}
                    className='c-article-nav__link'
                  >
                    <span className='c-article-nav__icon'>
                      <ArrowLeftIcon />
                    </span> Prev
                  </Link>
                </li>
            }
            {
              articles[1] &&
                <li
                  className='c-article-nav__item'
                >
                  <Link
                    to={articles[1].slug}
                    className='c-article-nav__link'
                  >
                    Next{` `}
                    <span className='c-article-nav__icon'>
                      <ArrowRightIcon />
                    </span>
                  </Link>
                </li>
            }
          </ul>
        </nav>
    }
  </aside>
)

export default ArticleViewFooter
