import Showdown from 'showdown'

import { HTMLContent } from 'Content'

const Converter = new Showdown.Converter()

const createContentBlockMarkdown = (contentBlocks) => (
  contentBlocks.map(block => {
    if (block.body === null) {
      return block
    } else {
      const newBlock = {
        type: block.type,
        body: Converter.makeHtml(block.body),
        contentComponent: HTMLContent
      }

      return Object.assign(block, newBlock)
    }
  })
)

export default createContentBlockMarkdown
