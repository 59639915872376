import React from 'react'

import Image from 'Image'

const ContentBlock2ColImage = ({ imageLeft, imageRight }) => (
  <div
    className='c-content-block'
  >
    <div
      className='o-container'
    >
      <div
        className='o-layout'
      >
        <figure
          className='o-layout__item u-width-1/2@md u-img-full u-margin-bottom-large u-margin-bottom-none@md'
        >
          <Image
            image={imageLeft}
            width={684}
            sizes={[
              `${Image.breakpoint('md')} 50vw`,
              '100vw'
            ]}
            alt={``}
          />
        </figure>
        <figure
          className='o-layout__item u-width-1/2@md u-img-full u-margin-bottom-none'
        >
          <Image
            image={imageRight}
            width={684}
            sizes={[
              `${Image.breakpoint('md')} 50vw`,
              '100vw'
            ]}
            alt={``}
          />
        </figure>
      </div>
    </div>
  </div>
)

export default ContentBlock2ColImage
