/**
 * Ensure an array of episodes has only one
 * episode per show.
 */
const getSiblingArticles = (allArticles, currentArticle) => {
  /* Find out where the currentArticle sits in the allArticles array */
  const currentArticleIndex = allArticles.findIndex((article, index) => {
    return article.title === currentArticle.frontmatter.title
  })

  const nextArticle = allArticles[currentArticleIndex - 1]
  const prevArticle = allArticles[currentArticleIndex + 1]

  const getNextArticle = () => {
    return nextArticle || allArticles[allArticles.length - 1]
  }

  const getPrevArticle = () => {
    return prevArticle || allArticles[0]
  }

  return [getNextArticle(), getPrevArticle()]
}

export default getSiblingArticles
