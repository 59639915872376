import React from 'react'

import Wrapper from './ContentBlockWrapper'

const ContentBlockVideo = ({ embedHtml }) => (
  <Wrapper
    wide
  >
    <figure
      className='u-margin-bottom-none'
    >
      <div dangerouslySetInnerHTML={{ __html: embedHtml }} className='c-youtube__embed' />
    </figure>
  </Wrapper>
)

export default ContentBlockVideo
