import React, { Fragment } from 'react'

import ContentBlock2ColImage from './ContentBlock2ColImage'
import ContentBlockFullWidthImage from './ContentBlockFullWidthImage'
import ContentBlockRichText from './ContentBlockRichText'
import ContentBlockEpisode from './ContentBlockEpisode'
import ContentBlockVideo from './ContentBlockVideo'
import ContentBlockYoutube from './ContentBlockYoutube'

const ContentBlockView = ({ type, ...props }) => (
  <Fragment>
    {/* Conditional Rendering with enums */}
    {{
      '2ColImage': <ContentBlock2ColImage {...props} />,
      fullWidthImage: <ContentBlockFullWidthImage {...props} />,
      richText: <ContentBlockRichText {...props} />,
      podcastEpisode: <ContentBlockEpisode {...props} />,
      video: <ContentBlockVideo {...props} />,
      youtube: <ContentBlockYoutube {...props} />
    }[type]}
  </Fragment>
)

export default ContentBlockView
