import React from 'react'

import Wrapper from './ContentBlockWrapper'

const ContentBlockVideo = ({ videoUrl }) => (
  <Wrapper
    wide
  >
    <figure
      className='u-margin-bottom-none'
    >
      {/* eslint-disable */}
      <video
        src={videoUrl}
        className='u-width-1/1'
        controls
      />
      {/* eslint-enable */}
    </figure>
  </Wrapper>
)

export default ContentBlockVideo
