import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Header from './ArticleViewHeader'
import Image from './ArticleViewImage'
import Body from './ArticleViewBody'
import Footer from './ArticleViewFooter'

const ArticleView = ({ title, author, featureImage, contentBlocks, tags, content, contentComponent, publishDate, slug, otherArticles, isPreview }) => (
  <Fragment>
    <Header
      title={title}
      author={author}
      publishDate={publishDate}
      slug={slug}
      tags={tags}
      isPreview={isPreview}
    />
    { featureImage && <Image
      image={featureImage.default}
    /> }
    <Body
      content={content}
      contentComponent={contentComponent}
      contentBlocks={contentBlocks}
      isPreview={isPreview}
    />
    <Footer
      articles={otherArticles}
      slug={slug}
      title={title}
      isPreview={isPreview}
    />
  </Fragment>
)

ArticleView.propTypes = {
  title: PropTypes.string.isRequired,
  author: PropTypes.string,
  featureImage: PropTypes.object,
  tags: PropTypes.array,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  publishDate: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  otherArticles: PropTypes.array
}

export default ArticleView
